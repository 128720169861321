body {
    margin: 0;
    font-family: 'Segoe UI', 'wf_segoe-ui_normal,helvetica', 'arial', 'sans-serif';
}

.powerbi-iframe-embed {
    position: relative;
    height: 100%;
    width: 100%;
    & iframe {
        border: none;
    }
}
